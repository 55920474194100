const dashboardTourMixin = {
  methods: {
    pageTour() {
      if (!this.$localStorage.tourOfPageFinished) {
        this.$nextTick(() => {
          const pageTour = this.$shepherd({
            useModalOverlay: true,
            defaultStepOptions: {
              cancelIcon: {
                enabled: true,
              },
              classes: 'vue-tour',
              scrollTo: true,
              popperOptions: {
                modifiers: [{
                  name: 'offset',
                  options: { offset: [0, 10] },
                }],
              },
            },
            scrollTo: { behavior: 'smooth', block: 'center' },
          });
          pageTour.on('cancel', this.setTheLocalStorageForPageTour);
          pageTour.on('complete', this.setTheLocalStorageForPageTour);
          pageTour.addStep({
            id: 'page-step-1',
            title: this.$t('tour.pageItem.step1.title'),
            text: this.$t('tour.pageItem.step1.text'),
            buttons: [
              {
                text: this.$t('tour.skip'),
                classes: 'light-grey-button prev',
                action: pageTour.cancel,
              },
              {
                text: this.$t('tour.next'),
                classes: 'green-button',
                action: pageTour.next,
              },
            ],
          });
          pageTour.addStep({
            id: 'page-step-2',
            title: this.$t('tour.pageItem.step2.title'),
            text: this.$t('tour.pageItem.step2.text'),
            attachTo: {
              element: document.querySelector('iframe').contentDocument.body.querySelector('#keditor-snippets-list'),
              on: 'left',
            },
            buttons: [
              {
                text: this.$t('tour.prev'),
                classes: 'light-grey-button prev',
                action: pageTour.back,
              },
              {
                text: this.$t('tour.next'),
                classes: 'green-button',
                action: pageTour.next,
              },
            ],
          });
          pageTour.addStep({
            id: 'page-step-3',
            title: this.$t('tour.pageItem.step3.title'),
            text: this.$t('tour.pageItem.step3.text'),
            attachTo: {
              element: document.querySelector('iframe').contentDocument.body.querySelector('#keditor-snippets-list'),
              on: 'left',
            },
            buttons: [
              {
                text: this.$t('tour.prev'),
                classes: 'light-grey-button prev',
                action: pageTour.back,
              },
              {
                text: this.$t('tour.next'),
                classes: 'green-button',
                action: pageTour.next,
              },
            ],
          });
          pageTour.addStep({
            id: 'page-step-4',
            title: this.$t('tour.pageItem.step4.title'),
            text: this.$t('tour.pageItem.step4.text'),
            attachTo: {
              element: '[data-page-step="4"]',
              on: 'bottom',
            },
            buttons: [
              {
                text: this.$t('tour.prev'),
                classes: 'light-grey-button prev',
                action: pageTour.back,
              },
              {
                text: this.$t('tour.next'),
                classes: 'green-button',
                action: pageTour.next,
              },
            ],
          });
          pageTour.addStep({
            id: 'page-step-5',
            title: this.$t('tour.pageItem.step5.title'),
            text: this.$t('tour.pageItem.step5.text'),
            attachTo: {
              element: '[data-page-step="5"]',
              on: 'bottom',
            },
            buttons: [
              {
                text: this.$t('tour.prev'),
                classes: 'light-grey-button prev',
                action: pageTour.back,
              },
              {
                text: this.$t('tour.finish'),
                classes: 'green-button',
                action: pageTour.complete,
              },
            ],
          });
          pageTour.start();
        });
      }
    },
    setTheLocalStorageForPageTour() {
      this.$localStorage.tourOfPageFinished = true;
    },
  },
};

export default dashboardTourMixin;

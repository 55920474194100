
//only way to make skin loading work!
var CKEDITOR_BASEPATH
if(window._AMS == undefined) {
 CKEDITOR_BASEPATH= window.location.origin+window.location.pathname+"_modules/templates/_keditor_shared/plugins/ckeditor-4.8.0/";
}



/*(function ($) {
    var KEditor = $.keditor;
    var flog = KEditor.log;

  	KEditor.prototype.testFunc2 = function(){
   		alert("stop2");
   }



})(jQuery);*/
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-row",
    { staticClass: "mb-4" },
    [
      _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "3" } }, [
        _c("label", [_vm._v(_vm._s(_vm.label))])
      ]),
      _c(
        "b-col",
        { attrs: { cols: "12", sm: "7" } },
        [
          _c("custom-js-editor", {
            attrs: { id: _vm.handle, jsCode: _vm.inputValue },
            on: {
              "update-code": function($event) {
                return _vm.$emit("update-field", $event)
              }
            }
          }),
          _vm.desc
            ? _c("b-form-text", { domProps: { innerHTML: _vm._s(_vm.desc) } })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }